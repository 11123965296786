<template>
  <div class="page flex-col justify-end">
    <div class="box_1 flex-col">
      <span class="text_1">APP可以做什么？</span>
      <span class="text_2">
        我们的App是一个全新的互动体验平台，旨在为用户提供便捷、个性化的服务。通过我们的小程序，智能导览、吃喝玩乐、热门活动、旅游路线、数字典藏、VR虚拟游、线上商城……App在手，应有尽有。
      </span>
      <img
        class="image_1"
        referrerpolicy="no-referrer"
        src="./assets/img/c2cfe5c50d992ab7bc42173c28fbef46.png"
      />
    </div>
    <div class="box_2 flex-row" id="top1">
      <img
        class="image_2"
        referrerpolicy="no-referrer"
        src="./assets/img/3c5972b4c703351f821df98b002b4a8e.png"
      />
      <div class="block_1 flex-col">
        <span class="text_3">功能亮点</span>
        <span class="text_4">活动预约</span>
        <span class="paragraph_1">
          在活动预定部分，进行预约，凭码入场，参与热门活动，玩转上街里。
        </span>
        <span class="text_5">智能导览</span>
        <div class="text-group_1 flex-col">
          <span class="paragraph_2">
            以上街里街区手绘图为基础，集图、文、位置、点位、动线为
            一体的智慧服务，展示街区啤酒节会场、停车场（实时车位查
            看及车位预约）、生活服务，休闲娱乐以及厕所等服务设施。
            实现GPS定位、一键导航、图文介绍、公共服务、吃住行游购
            娱推荐等。
          </span>
          <span class="text_6">旅游路线</span>
          <span class="paragraph_3">
            提供文化游、主题游、研学游、亲子游、一日游等各类主题路
            线详情，减少游客出行路线烦恼
          </span>
        </div>
        <span class="text_7">VR虚拟游</span>
        <div class="text-group_2 flex-col justify-between">
          <span class="paragraph_4">
            采用先进的虚拟现实技术，展示街区全貌，让您“行走”在虚拟
            的街区里。身临其境地感受到街区震撼的视觉效果，带给您前
            所未有的沉浸式体验。
          </span>
          <span class="text_8">游玩攻略</span>
        </div>
        <span class="paragraph_5">
          上街里，哈啤酒，品老城。攻略都为您准备好了，趁着闲暇，
          给心情放个假。
        </span>
      </div>
    </div>
    <div class="box_3 flex-col" id="top2">
      <div class="group_1 flex-row">
        <div class="group_2 flex-col">
          <span class="text_9">联系我们</span>
          <div class="text-wrapper_1 flex-row justify-between">
            <span class="text_10">商务合作：</span>
            <span class="text_11">18603596606</span>
          </div>
          <span class="text_12">
            温馨提示：本网站所刊登的各种文字和图片信息和各种专题专栏资料，均为中山路上街里所有，未经协议授权，禁止下载使用。
          </span>
          <!-- <div class="group_3 flex-row justify-between">
            <div class="section_1 flex-col justify-between">
              <div class="image-wrapper_1 flex-col">
                <img
                  class="image_3"
                  referrerpolicy="no-referrer"
                  src="./assets/img/0fedfc3784edbdb81729a9c19fd3c021.png"
                />
              </div>
              <span class="text_13">官方微信</span>
            </div>
            <div class="image-text_1 flex-col justify-between">
              <div class="box_4 flex-col"></div>
              <span class="text-group_3">官方小程序</span>
            </div>
          </div> -->
        </div>
        <img
          class="image_4"
          referrerpolicy="no-referrer"
          src="./assets/img/68bd00a0adc6910f017c91930a5cea02.png"
        />
      </div>
    </div>
    <div class="text-wrapper_2 flex-col">
      <span class="text_14" @click="linkBen">Copyright(C)2023&nbsp;QINGWANGFU&nbsp;版权所有：中山路上街里&nbsp;晋ICP备16005757号-3</span>
    </div>
    <div class="box_5 flex-col">
      <div class="box_6 flex-row">
        <div class="nav_area" flex-row>
        <span class="text_15" :class="{active:navActive==1}" @click="goNav(1)">网站首页</span>
        <span class="text_16" :class="{active:navActive==2}" @click="goNav(2)">功能介绍</span>
        <span class="text_17" :class="{active:navActive==3}" @click="goNav(3)">联系我们</span>
        <img
          class="image_5"
          :class="`move${navActive}`"
          referrerpolicy="no-referrer"
          src="./assets/img/1f6c0b0ba546ad900ea4f383fc299c41.png"
        />
        </div>
      </div>
      <div class="box_7 flex-row">
        <div class="group_4 flex-col">
          <img
            class="image_6"
            referrerpolicy="no-referrer"
            src="./assets/img/164bd143a29f56d6cfd962d1a55e928c.png"
          />
          <span class="text_18">嗨玩上街里丨【中山路上街里】APP上线啦！</span>
          <span class="text_19">百年老街区智慧化升级，为您提供全方位优质服务</span>
          <div class="box_8 flex-row justify-between">
            <div class="image-wrapper_2 flex-col">
              <img
                class="image_7"
                referrerpolicy="no-referrer"
                src="./assets/img/jJ524k.png"
              />
            </div>
            <div class="section_2 flex-col justify-between">
              <div class="box_9 flex-row" @click="linkDownload">
                <div class="image-text_2 flex-row justify-between">
                  <img
                    class="label_1"
                    referrerpolicy="no-referrer"
                    src="./assets/img/9e5b7f9f8276019e14499f721eabad86.png"
                  />
                  <span class="text-group_4">Android版下载</span>
                </div>
              </div>
              <div class="box_10 flex-row" @click="linkDownload">
                <div class="image-text_3 flex-row justify-between">
                  <img
                    class="label_2"
                    referrerpolicy="no-referrer"
                    src="./assets/img/681a7c2bf6c71a26da9d5e7555d6fbd7.png"
                  />
                  <span class="text-group_5">iPhone版下载</span>
                </div>
              </div>
            </div>
          </div>
          <span class="text_20">手机扫码下载APP</span>
        </div>
        <img
          class="image_8"
          referrerpolicy="no-referrer"
          src="./assets/img/95dd251325b36cf0595c373efa543def.png"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      constants: {},
      navActive:1
    };
  },
  methods: {
    goNav(id){
      this.navActive = id;
      const top1 = document.getElementById('top1').offsetTop;
      const top2 = document.getElementById('top2').offsetTop;
      switch (id) {
        case 1:
          window.scrollTo({
            top:0,
            behavior: 'smooth'
          })
          break;
        case 2:
          window.scrollTo({
            top:top1,
            behavior: 'smooth'
          })
          break;
        case 3:
          window.scrollTo({
            top:top2,
            behavior: 'smooth'
          })
          break;
        default:
          break;
      }
      
    },
    linkDownload(){
        window.open("https://www.pgyer.com/jJ524k")
    },
    linkBen(){
      window.open("https://beian.miit.gov.cn/")
    }
  }
};
</script>
<style scoped lang="scss" src="./assets/index.scss" />